<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="pa-4 mb-4" elevation="2">
            <v-row>
              <v-col cols="12">
                <h4 class="text-center">Filters</h4>
              </v-col>
              <v-col cols="12">
                <div>
                  <h3 style="font-size: 16px;">
                    Zoek op land, nummer en omschrijving
                  </h3>
                </div>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Opzoeken"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="minPrice"
                  type="number"
                  prefix="€"
                  hint="Minimale prijs in"
                  persistent-hint
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="maxPrice"
                  type="number"
                  prefix="€"
                  hint="Maximale prijs in"
                  persistent-hint
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="minKavelNr"
                  type="number"
                  hint="Begin van kavelnummer"
                  persistent-hint
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="maxKavelNr"
                  type="number"
                  hint="Einde van kavelnummer"
                  persistent-hint
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- Tabel Rechts -->
        <v-col cols="12" md="9">
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="print()">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="isFetching ? [] : items"
              :search="search"
              :loading="isFetching"
              disable-pagination
              :fixed-header="true"
              height="75vh"
              :hide-default-footer="true"
              class="elevation-1"
              id="my-partijen"
              cellClass="printable"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="primary" @click="openKavelDetails(item.kavelnr)">
                  partij bekijken
                </v-btn>
              </template>
              <template v-slot:top> </template>
              <template v-slot:[`item.price`]="{ value }">
                €{{ value }}
              </template>
              <template v-slot:footer>
                <v-pagination
                  v-model="page"
                  class="my-4 pb-4"
                  :length="totalPages"
                  :total-visible="7"
                  @input="onPageChange"
                ></v-pagination>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LoggingService from "../components/Services/LoggingService";
import apiKavel from "../components/apiKavel";
function debounce(func, delay) {
  let debounceTimer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}
export default {
  data: () => ({
    minPrice: null,
    maxPrice: null,
    minKavelNr: null,
    maxKavelNr: null,
    page: 1,
    isFetching: false,
    search: "",
    items: [],
    headers: [
      {
        text: "Kavel nummer",
        value: "kavelnr",
        sortable: true,
      },

      {
        text: "Land",
        value: "land.Ned",
        sortable: true,
      },

      {
        text: "Omschrijving",
        value: "txtNed",
        sortable: true,
      },

      {
        text: "Type",
        value: "embNed",
        sortable: true,
      },

      {
        text: "Prijs",
        value: "price",
        sortable: true,
        formatter: (value) => `€${value}`,
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    this.isFetching = true;
    const laatsteZoekopdracht = localStorage.getItem("laatsteZoekopdracht");
    const opgeslagenPagina = localStorage.getItem("laatsteBekekenPagina");
    if (opgeslagenPagina) {
      this.page = Number(opgeslagenPagina);
    } else if (this.$route.query.land) {
      this.search = this.$route.query.land;
    }
    this.search = laatsteZoekopdracht || "";
    await this.getKavels(this.page);
    if (this.$route.query.land) {
      this.search = this.$route.query.land;
    }
  },

  computed: {
    modifiedHeaders() {
      return this.headers;
    },

    modifiedHeadersWithoutActions() {
      return this.headers.filter((header) => header.value !== "Actions");
    },
    paginatieReeks() {
      let reeks = [];
      const paginaBuffer = 2; // Aantal pagina's voor en na de huidige pagina
      const ellipses = "...";
      const startPagina = Math.max(this.page - paginaBuffer, 1);
      const eindPagina = Math.min(this.page + paginaBuffer, this.totalPages);

      if (startPagina > 1) {
        reeks.push(1); // Voeg de eerste pagina altijd toe
        if (startPagina > 2) reeks.push(ellipses); // Voeg ellipses toe als er een gat is
      }

      for (let i = startPagina; i <= eindPagina; i++) {
        reeks.push(i);
      }

      if (eindPagina < this.totalPages) {
        if (eindPagina < this.totalPages - 1) reeks.push(ellipses);
        reeks.push(this.totalPages);
      }

      return reeks;
    },
  },
  watch: {
    search(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFetching = true;
        this.queryHandler();
      }
    },
    minPrice(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFetching = true;
        this.queryHandler();
      }
    },
    maxPrice(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFetching = true;
        this.queryHandler();
      }
    },
    minKavelNr(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFetching = true;
        this.queryHandler();
      }
    },
    maxKavelNr(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFetching = true;
        this.queryHandler();
      }
    },
  },

  methods: {
    queryHandler: debounce(function() {
      this.page = 1;
      localStorage.setItem("laatsteZoekopdracht", this.search);
      this.getKavels(1);
    }, 500),
    async getKavels(pageNumber) {
      this.isFetching = true;
      console.log("API parameters: ", params);
      const params = {
        page: pageNumber,
        q: this.search,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        minKavelNr: this.minKavelNr,
        maxKavelNr: this.maxKavelNr,
      };

      await apiKavel
        .getCollectionKavels(params)
        .then((data) => {
          this.items = data.kavels;
          this.totalPages = data.totalPages;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },

    onPageChange(pageNumber) {
      this.page = pageNumber;
      this.getKavels(pageNumber);
    },

    openKavelDetails(kavelnr) {
      const eventData = {
        website: "NL",
        eventType: "productClick",
        productId: kavelnr,
        timestamp: new Date().toISOString(),
      };
      LoggingService.logEvent(eventData);
      //logging
      localStorage.setItem("laatsteBekekenPagina", this.page.toString());

      this.$router.push(`/details/${kavelnr}`);
    },
    print() {
      const printContents = document.getElementById("my-partijen").outerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
  },
};
</script>
