<template>
  <Partijen />
</template>

<script>
import Partijen from "@/components/Partijen";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Partijen,
  },
  metaInfo: {
    title: "Partijen |Postzegels ",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
    // meta: [
    //   { charset: "utf-8" },
    //   {
    //     name: "description",
    //     content: "Algemenevoorwaarden met kantoorflex Nederland.",
    //   },
    //   { name: "viewport", content: "width=device-width, initial-scale=1" },
    // ],
  },
};
</script>
